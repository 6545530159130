<template>
    <section>
        <modal ref="modalDetalleProducto" icon="box" titulo="Detalle del producto" no-aceptar no-cancelar tamano="modal-lg">
            <div class="row mx-0 justify-center">
                <div class="col-auto">
                    <img :src="producto.imagen" height="140" width="140" class="border br-10 cr-pointer obj-cover" @click="getImages()"/>
                </div>
                <div class="col-5 px-0">
                    <div class="row mx-0 my-2 f-600 f-14 justify-content-start text-general">
                        <div class="col-12">
                            <p class="nombre f-17 f-600 text-general">
                                {{ producto.nombre }}
                            </p>
                        </div>
                        <div class="col-auto">
                            {{ producto.precio }}
                        </div>
                    </div>
                    <div class="row mx-0 my-2 mt-3 f-15 text-general pl-3">
                        <div class="col-10 text-left ">
                            {{ producto.descripcion }}
                        </div>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general pl-3">
                        <b class="mr-2">
                            Barcode:
                        </b>
                        <p>{{ producto.barcode != null ? producto.barcode : 'No registra' }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general pl-3">
                        <b class="mr-2">
                            Sku:
                        </b>
                        <p>{{ producto.sku != null ? producto.sku : 'No registra' }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general pl-3">
                        <b class="mr-2">
                            Presentación:
                        </b>
                        <p>{{ producto.presentacion }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general pl-3">
                        <b class="mr-2 ">
                            Unidad de medida:
                        </b>
                        <p>{{ producto.unidad }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general pl-3">
                        <b class="mr-2">
                            Venta mínima:
                        </b>
                        <p>{{ producto.cantidad_minima }} {{ producto.unidad }}.</p>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general pl-3">
                        <b class="mr-2">
                            Cantidad Pedida:
                        </b>
                        <p>{{ producto.cantidad_pedida }}</p>
                    </div>
                    <div class="row mx-0 my-2 f-15 text-general pl-3">
                        <b class="mr-2">
                            Valor Total
                        </b>
                        <p> {{ producto.valor_total }}</p>
                    </div>
                </div>
                <div v-show="producto.kit" class="col pr-0">
                    <productos-kits ref="detalleKit" />
                </div>
            </div>
        </modal>
        <modal-imagenes ref="modalImagnes" @cerrar="$refs.modalDetalleProducto.toggle()" />
    </section>
</template>

<script>
import Productos from '~/services/productos/productos'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            estado: '',
            producto:{nombre:''},
            datos:{},
            idm_moneda:1
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
        }),
    },
    methods: {
        async datos_cambio(info,idm_moneda){
            try {
                this.idm_moneda = idm_moneda

                this.datos = info

                let decimalFraccion = parseInt(info.producto.cantidad_tipo) == 1 ? 0 : 2;

                this.producto = {
                    id:info.producto.id,
                    nombre:info.producto.nombre,
                    descripcion:info.producto.descripcion,
                    imagen:info.producto.imagen,
                    precio: this.convertMoney(info.unidad_final,this.idm_moneda),
                    presentacion:info.producto.presentacion,
                    unidad:info.producto.unidad,
                    cantidad_minima:this.agregarSeparadoresNumero(info.producto.cantidad_minima, decimalFraccion),
                    cantidad_pedida: this.agregarSeparadoresNumero(info.cantidad, decimalFraccion) + info.producto.unidad ,
                    valor_total: this.convertMoney(info.total_final,this.idm_moneda),
                    kit:info.kit,
                    barcode: info.barcode,
                    sku: info.sku,

                }
                if(this.producto.kit){
                    await this.$refs.detalleKit.getProductosKit(this.producto.id)
                }
                this.$refs.modalDetalleProducto.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        async detalle_producto_tendero(info,idm_moneda){ // funcion es para armar el modal pero con daos para el tendero
            try {
                this.idm_moneda = idm_moneda

                this.datos = info

                let decimalFraccion = parseInt(info.producto.cantidad_tipo) == 1 ? 0 : 2;

                this.producto = {
                    id:info.producto.id,
                    nombre:info.producto.nombre,
                    descripcion:info.producto.descripcion,
                    imagen:info.producto.imagen,
                    precio: this.convertMoneyTendero(info.unidad_final,this.idm_moneda),
                    presentacion:info.producto.presentacion,
                    unidad:info.producto.unidad,
                    cantidad_minima:this.agregarSeparadoresNumero(info.producto.cantidad_minima, decimalFraccion),
                    cantidad_pedida: this.agregarSeparadoresNumero(info.cantidad, decimalFraccion) + info.producto.unidad ,
                    valor_total: this.convertMoneyTendero(info.total_final,this.idm_moneda),
                    kit:info.kit

                }

                if(this.producto.kit){
                    await this.$refs.detalleKit.getProductosKit(this.producto.id)
                }
                this.$refs.modalDetalleProducto.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        // valor_producto(){
        //     if(this.pedido.impuestos === 0){
        //         return this.info.total_final
        //     }
        //     if(this.pedido.impuestos === 1){
        //         return this.info.total_final
        //     }
        //     if(this.pedido.impuestos === 2 || this.pedido.impuestos === 3){
        //         return this.info.total_final * (1 + this.impuesto / 100)
        //     }
        //     return 0
        // },
        async getImages(){

            const {data} = await Productos.getImages(this.producto.id)
            if(data.imagenes.length === 0) return
            this.$refs.modalDetalleProducto.toggle()
            this.$refs.modalImagnes.toggle(data.imagenes)
        },
    }
}
</script>
